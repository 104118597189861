<template>
  <div class="vld-parent">
    <loading
      :active.sync="isLoading"
      :can-cancel="true"
      :is-full-page="fullPage"
      color="#5159F9"
      :loader="'bars'"
    />
  </div>
</template>
 
<script>
import { mapState } from 'vuex'
// Import component
import Loading from 'vue-loading-overlay'
// Import stylesheet
import 'vue-loading-overlay/dist/vue-loading.css'

export default {
  components: {
    Loading,
  },
  data() {
    return {
      fullPage: true,
    }
  },
  computed: {
    ...mapState({
      isLoading: state => state.common.isLoading,
    }),
  },
}
</script>
